var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticClass:"page-title d-block d-lg-none pt-2 pb-0 pr-0"},[_vm._v(_vm._s(_vm.$route.meta.name))]),_c('v-breadcrumbs',{staticClass:"pb-0 mb-0 pt-3 mr-0 pr-0",attrs:{"items":_vm.items}}),_c('v-card',{staticClass:"my-4 pb-3 elevation-25"},[_c('v-card-title',{staticClass:"pt-0"},[_c('h3',{staticClass:"card-title pmd-title-separator"},[_vm._v(_vm._s(_vm.$t(("$vuetify." + _vm.title))))]),_c('v-spacer',{ref:"counselorClients"}),_c('v-text-field',{staticClass:"ma-3",staticStyle:{"max-width":"350px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('$vuetify.searchLabel')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.clients,"search":_vm.search},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getClientProfile(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.family)+" ")])]}},{key:"item.cycle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.rtl ? item.cycle.heading : _vm.showCycleHeadingInEn(item.cycle.heading))+" ")]}},{key:"item.nextSession",fn:function(ref){
var item = ref.item;
return [(item.next_session!=null)?[_vm._v(" "+_vm._s(item.next_session.diff_time)+" ")]:_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","block":"","small":""},on:{"click":function($event){return _vm.getClientProfile(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('$vuetify.counselorClientList.ViewFile'))+" ")])]}}])})],1),(_vm.total > 1)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.total},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }