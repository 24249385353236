<template>
  <div>
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <v-card class="my-4 pb-3 elevation-25">
      <v-card-title class="pt-0">
        <h3 class="card-title pmd-title-separator">{{ $t(`$vuetify.${title}`) }}</h3>
        <v-spacer ref="counselorClients"></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('$vuetify.searchLabel')"
            class="ma-3"
            style="max-width: 350px"
        ></v-text-field>
      </v-card-title>

      <v-data-table
          hide-default-footer
          :headers="headers"
          :items="clients"
          :search="search"
      >
        <template v-slot:item.fullname="{ item }">
          <div @click="getClientProfile(item.id)" style="cursor: pointer;">
            {{ item.name }} {{item.family}}
          </div>
        </template>
        <template v-slot:item.cycle="{ item }">
          {{$vuetify.rtl ? item.cycle.heading : showCycleHeadingInEn(item.cycle.heading)}}
        </template>
        <template v-slot:item.nextSession="{ item }">
          <template v-if="item.next_session!=null">
            {{item.next_session.diff_time}}
          </template>

          <!--<template v-if="nextSessions[item.id] != null">-->
          <!--{{nextSessions[item.id]}}-->
          <!--</template>-->
          <!--<template v-if=" != null "></template>-->
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn rounded block class="primary" @click="getClientProfile(item.id)" small>
            {{$t('$vuetify.counselorClientList.ViewFile')}}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center" v-if="total > 1">
      <v-pagination
          v-model="page"
          :length="total"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
// import {mapGetters} from 'vuex'
import {EventBus} from "@/event-bus";

export default {
  props: {
    title: {
      default: 'counselorClientList.title',
      type: String
    },
    fiveSession: Boolean
  },
  data() {
    return {
      // loading: true,
      nextSessions: [],
      search: '',
      headers: [
        {
          text: this.$t('$vuetify.counselorClientList.patient'),
          align: 'start',
          sortable: false,
          value: 'fullname',
        },
        {text: this.$t('$vuetify.counselorClientList.nextSession'), value: 'nextSession', sortable: false},
        {text: this.$t('$vuetify.counselorClientList.cycle'), value: 'cycle', sortable: false},
        {text: this.$t('$vuetify.counselorClientList.UserInformation'), value: 'action', sortable: false},
      ],
      desserts: [],
      page: 1,
      total: null,
      clients: [],
      items: [
        {
          text: this.$t('$vuetify.counselorNavItem.dashboard'),
          disabled: false,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/dashboard`,
        },
        {
          text:  this.$t('$vuetify.counselorNavItem.clients'),
          disabled: true,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/clients`,
        }
      ]
    }
  },
  computed: {
    // ...mapGetters({
    //     clients: 'getCounselorClients',
    // }),
  },
  created() {
    this.getCounselorClients();
  },
  methods: {
    getCounselorClients() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getCounselorClients', {page: this.page}).then((resp) => {
        // this.nextSessions = resp.data.next;
        this.total = resp.data.pagination.total_pages;
        // this.clients = resp.data.group.clients;
        this.clients = resp.data.users;

      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    // nextPage() {
    //     this.$store.dispatch('pageChangeHandle', {
    //         value: 'next', page: this.page,
    //         url: '/counselor/users/list',
    //         commitName: 'setCounselorClients'
    //     });
    // },
    // previousPage() {
    //     this.$store.dispatch('pageChangeHandle', {
    //         value: 'previous', page: this.page,
    //         url: '/counselor/users/list',
    //         commitName: 'setCounselorClients'
    //     });
    // },
    // specificPage(page) {
    //     this.$store.commit('SET_SPECIFIC_PAGE', page);
    //     this.$store.dispatch('pageChangeHandle', {
    //         value: '', page: page,
    //         url: '/counselor/users/list',
    //         commitName: 'setCounselorClients'
    //     });
    // },
    getClientProfile(id) {
      this.$router.push('clients/' + `${id}` + '/profile');
      // this.$store.dispatch('getClientInfo', id).then((data) => {
      //
      // })
    },
    showCycleHeadingInEn(input){
      switch (input){
        case 'فردی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[0]')
          break;
        case 'زوج':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[1]')
          break;
        case 'خانواده':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[2]')
          break;
        case 'کودک و نوجوان':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[3]')
          break;
        case 'سکس تراپی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[4]')
          break;
        case 'روانپزشکی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[5]')
          break;
      }
      return input
    }
  },
  watch: {
    page() {
      this.getCounselorClients();
      this.$vuetify.goTo(this.$refs.counselorClients.offsetTop);
    }
  }
}
</script>

<style scoped>

</style>